<template>
  <div class="container">
    <div @click="goback" style="font-size:14px;margin-bottom:10px;cursor: pointer;width:50px">
      <i class="el-icon-back"></i>
      返回
    </div>
    <el-form ref="form" :model="form" style="width:600px" :rules="rules">
        <el-form-item prop="client_app_code" label="app唯一标识" label-width="170px">
          <el-select v-model="form.client_app_code">
            <el-option
              v-for="item in applist"
              :key="item.code"
              :label="item.name"
              :value="item.code">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="region" label="所属区域" label-width="170px">
          <el-select v-model="form.region">
            <el-option
              v-for="item in regionlist"
              :key="item.code"
              :label="item.name"
              :value="item.code">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="host" label="发件服务器域名" label-width="170px">
          <el-input v-model="form.host"></el-input>
        </el-form-item>
        <el-form-item prop="port" label="发件服务器端口" label-width="170px">
          <el-input v-model="form.port"></el-input>
        </el-form-item>
        <el-form-item prop="username" label="发件昵称" label-width="170px">
          <el-input v-model="form.username"></el-input>
        </el-form-item>
        <el-form-item prop="password" label="发件密码" label-width="170px">
          <el-input v-model="form.password"></el-input>
        </el-form-item>
        <el-form-item prop="from" label="显示的发件人邮箱账号" label-width="170px">
          <el-input v-model="form.from"></el-input>
        </el-form-item>
        <el-form-item prop="unit" label="发件人昵称" label-width="170px">
          <el-input v-model="form.unit"></el-input>
        </el-form-item>
        <el-form-item prop="secure" label="是否采用加密" label-width="170px">
          <el-radio-group v-model="form.secure">
            <el-radio label="true">是</el-radio>
            <el-radio label="false">否</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label-width="170px">
            <el-button type="primary" @click="Validate(onSubmit)">{{submitbutton}}</el-button>
            <!-- <el-button>取消</el-button> -->
        </el-form-item>
    </el-form>

  </div>
</template>

<script type="text/ecmascript-6">
import api from '../../../../axios/emial'
import Regionapi from '../../../../axios/pack'
export default {
  name: '',
  mixins: [],
  components: {
  },
  props: {},
  data () {
    return {
      form: {
        id: '',
        client_app_code: '',
        region: '',
        host: '',
        port: '',
        username: '',
        password: '',
        from: '',
        unit: '',
        secure: '',
      },
      submitbutton: '',
      rules: {
        client_app_code: [{ required: true, message: '请输入app唯一标识', trigger: 'blur' }],
        region: [{ required: true, message: '请输入所属区域', trigger: 'blur' }],
        host: [{ required: true, message: '请输入发件服务器域名', trigger: 'blur' }],
        port: [{ required: true, message: '请输入发件服务器端口', trigger: 'blur' }],
        username: [{ required: true, message: '请输入发件昵称', trigger: 'blur' }],
        password: [{ required: true, message: '请输入发件密码', trigger: 'blur' }],
        from: [{ required: true, message: '请输入显示的发件人邮箱账号', trigger: 'blur' }],
        unit: [{ required: true, message: '请输入发件人昵称', trigger: 'blur' }],
        secure: [{ required: true, message: '请输入是否采用加密', trigger: 'blur' }],
      },
      applist: [],
      regionlist: []
    }
  },
  computed: {},
  watch: {},
  methods: {
    GetRegion () {
      Regionapi.ReginList({
        page: 1,
        limit: 100,
      }).then(res => {
        if (res.data.code == 200) {
          this.regionlist = res.data.data.data
          return
        }
        this.$message.error('获取失败' + res.data.msg)
      })
    },
    GetAppList(){
      this.api.ClientList({
        page: 1,
        limit: 100,
      }).then(res => {
        if (res.data.code == 200) {
          this.applist = res.data.data.data
          this.GetRegion()
          return
        }
        this.$message.error('获取失败' + res.data.msg)
      })
    },
    GetappEmial(){
      api.AppEmailDetail({
        id: this.form.id
      }).then(res => {
        if (res.status == 200) {
          this.form = res.data.data
          return
        }
        this.$message.error('获取失败' + res.data.msg)
      })
    },
    goback () {
      this.$router.push('/iot/app/email')
    },
    Validate: function (call) {
      this.$refs['form'].validate(res => {
        if (res && call) {
          call()
        }
      })
    },
    onSubmit () {
      api.AppEmailUpdate({
        id: +this.form.id,
        client_app_code: this.form.client_app_code,
        region: this.form.region,
        host: this.form.host,
        port: +this.form.port,
        username: this.form.username,
        password: this.form.password,
        from: this.form.from,
        unit: this.form.unit,
        secure: this.form.secure
      }).then(res => {
        if (res.data.code == 200) {
          this.form = {}
          this.$confirm(this.submitbutton+'成功,是否返回列表页?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            this.goback()
          })
          return
        }
        this.$message.error(this.submitbutton +'失败' + res.data.msg)
      })
    }
  },
  filters: {},
  mounted () {
    if (this.$route.params.id !== '0') {
      this.submitbutton = '修改'
      this.form.id = this.$route.params.id
      this.GetappEmial()
    } else{
      this.submitbutton = '创建'
    }
  },
  created () {
    this.GetAppList()
  }
}
</script>

<style scoped lang="stylus" rel="stylesheet/stylus">
</style>
