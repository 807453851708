import { fetch } from './fetch'
import api from './url'

export default {
  // 套餐管理
  AppEmailUpdate: function (params) {
    return fetch({
      url: api.AppEmailUpdate,
      method: 'post',
      data: params
    })
  },
  AppEmailList: function (params) {
    return fetch({
      url: api.AppEmailList,
      method: 'get',
      params: params
    })
  },
  AppEmailDelete: function (params) {
    return fetch({
      url: api.AppEmailDelete,
      method: 'post',
      data: params
    })
  },
  AppEmailDetail: function (params) {
    return fetch({
      url: api.AppEmailDetail,
      method: 'get',
      params: params
    })
  },
}